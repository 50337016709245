.audio-items {
  margin-bottom: 20px;
}
.items {
  --gap: 4px;
  --width: 72px;
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(var(--width), 1fr));
}
@media (min-width: 30em) {
  .items {
    --gap: 6px;
    --width: 100px;
  }
}
@media (min-width: 43em) {
  .items {
    --gap: 20px 8px;
    --width: 144px;
  }
}
