.audio-item {
  --shadow-y: 2px;
  --shadow-blur: 4px;
  --title-color: var(--primary-dark);
  --sub-color: var(--primary-dark-transparent);
  --icon-size: 22px;
  position: relative;
}

.audio-button {
  position: relative;
  width: 100%;
  padding: 0;
  background: white;
  padding: calc(3 / 72 * 100%);
  border-radius: calc(6 / 72 * 100%);
  -webkit-box-shadow: 0 var(--shadow-y) var(--shadow-blur) rgba(0, 0, 0, 0.35);
  box-shadow: 0 var(--shadow-y) var(--shadow-blur) rgba(0, 0, 0, 0.35);
  border: none;
  -webkit-appearance: none;
}
.audio-image {
  position: relative;
  border-radius: calc(3 / 66 * 100%);
  overflow: hidden;
  background: var(--primary-transparent);
  z-index: 1;
}
.audio-image::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.audio-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.grid-icon {
  --grid-position: 4px;
  position: absolute;
  bottom: var(--grid-position);
  left: var(--grid-position);
  color: white;
}
.audio-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}
.audio-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--secondary);
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.audio-overlay .icon {
  fill: white;
  width: var(--icon-size);
  height: var(--icon-size);
}
.audio-overlay.is-active::before {
  opacity: 0.5;
}
.audio-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: calc(8 / 72 * 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
}
.audio-title {
  color: var(--title-color);
}
.audio-title:not(:last-child) {
  margin-bottom: 0;
}
.audio-title-main,
.audioTitleMain,
.audio-title-sub {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.audio-title-main {
  font-size: 14px;
  margin-bottom: 0.15em;
}

.audioTitleMain {
  font-size: 14px;
  margin-bottom: 0.15em;
}

.audio-title-sub {
  color: var(--sub-color);
  font-size: 12px;
  font-family: var(--font-sub);
  font-weight: normal;
  font-style: italic;
}
.audio-footer {
  display: none;
}
@media (min-width: 43em) {
  .audio-item {
    --shadow-y: 4px;
    --shadow-blur: 8px;
    --title-color: white;
    --sub-color: rgba(white, 0.75);
    --icon-size: 28px;
  }
  .audio-content {
    position: relative;
    margin: 14px 0 0;
    padding: 0 5px;
    pointer-events: auto;
  }
  .grid-icon {
    --grid-item: 4px;
    --grid-position: 8px;
  }
}
