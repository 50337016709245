.icon {
  fill: transparent;
  width: var(--icon-size);
  height: var(--icon-size);
}

.audioOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.audioOverlay:hover {
  background-color: var(--secondary);
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.audioOverlay:hover > .icon {
  fill: white;
}
